import { DialogType } from "..";
import BaseDialogCtrl from "../base/controller";
import PurchaseItem from "~/models/item/purchase_item";

export default class CrystalPurchaseDialogCtrl extends BaseDialogCtrl {
  _type = DialogType.CrystalPurchase;

  /**
   * 購入する商品
   * @type {PurchaseItem}
   */
  item;
  /**
   * クレカ前回情報支払い
   * @type Boolean
   */
  is_cust_id_purchasable;
  /**
   * クレカ前回情報
   * @type Object
   */
  last_credit_card_info;

  /**
   * @param {PurchaseItem} item - 購入する商品
   */
  constructor(item,is_cust_id_purchasable, last_credit_card_info) {
    super();
    this.item = item;
    this.is_cust_id_purchasable = is_cust_id_purchasable;
    this.last_credit_card_info = last_credit_card_info;
  }

  /**
   * @return {string}
   */
  get countText() {
    return `×${this.item.item_count}個`;
  }
  
  /**
   * @return {string}
   */
  get priceText() {
    return `¥${this.item.price}`;
  }

  /**
   * @return {string}
   */
  get itemImgFilename() {
    return this.item.icon_url;
  }

  /**
   * @return {string}
   */
  get seihin_id() {
    return this.item.seihin_id;
  }

  get remain_count() {
    return this.item.remain_count;
  }
}
