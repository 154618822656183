<template>
  <img :src="src" />
</template>

<script>
/**
 * @module
 * @vue-props {String} fileName
 */
export default defineNuxtComponent({
  name: "jpblIconCrystalImg",
});
</script>

<script setup>
const config = useRuntimeConfig();

const iconCrystal1 = config.public.SNS_PATH + "sp/img/layout/blg/icon-crystal-1.png";
const iconCrystal2 = config.public.SNS_PATH + "sp/img/layout/blg/icon-crystal-2.png";
const iconCrystal3 = config.public.SNS_PATH + "sp/img/layout/blg/icon-crystal-3.png";
const iconCrystal4 = config.public.SNS_PATH + "sp/img/layout/blg/icon-crystal-4.png";
const iconCrystal5 = config.public.SNS_PATH + "sp/img/layout/blg/icon-crystal-5.png";
const iconCrystal6 = config.public.SNS_PATH + "sp/img/layout/blg/icon-crystal-6.png";

const imgMap = {
  "icon-crystal-1.png": iconCrystal1,
  "icon-crystal-2.png": iconCrystal2,
  "icon-crystal-3.png": iconCrystal3,
  "icon-crystal-4.png": iconCrystal4,
  "icon-crystal-5.png": iconCrystal5,
  "icon-crystal-6.png": iconCrystal6,
};

const props = defineProps({
  fileName: {
    type: String,
    required: true,
  },
});

const src = imgMap[props.fileName];
</script>

<style lang="scss" scoped></style>
